import * as React from 'react'
// eslint-disable-next-line import/no-cycle
import { cdnUrl } from 'utils/storyblok'
import UspGroupComponent from './UspGroup'

export const transformProps = (propsArg) => {
  const props = { ...propsArg }

  props.items = props.items.map((item) => ({
    ...item,
    backgroundMediaSrc:
      item.backgroundMedia?.filename &&
      (item.backgroundMedia?.filename.indexOf('.svg') > -1
        ? item.backgroundMedia?.filename
        : cdnUrl(item.backgroundMedia?.filename)),
    mediaSrc:
      item.mediaSrc?.filename &&
      (item.mediaSrc?.filename.indexOf('.svg') > -1
        ? item.mediaSrc?.filename
        : cdnUrl(item.mediaSrc?.filename)),
  }))

  return props
}
const UspGroup = (props) => <UspGroupComponent {...transformProps(props)} />

export default UspGroup
