import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import ButtonBase from '@material-ui/core/ButtonBase'
import ArrowPrevIcon from '../icons/ArrowPrev'
import ArrowNextIcon from '../icons/ArrowNext'

export const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    transition: theme.transitions.create(['all'], {
      duration: 550,
      easing: theme.transitions.easing.easeOut,
    }),
    '&[disabled]': {
      opacity: 0.25,
    },
  },
  icon: {
    flexGrow: 1,
  },
  label: {
    ...theme.typography.caption,
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    minWidth: 80,
  },
})

const SlideshowNavigation = React.forwardRef(function SlideshowNavigation(props, ref) {
  const {
    classes,
    className,
    listLength,
    nextButtonDisabled,
    NextButtonProps: { style: NextButtonStyle, ...NextButtonProps } = {},
    onNextClick,
    onPrevClick,
    prevButtonDisabled,
    PrevButtonProps: { style: PrevButtonStyle, ...PrevButtonProps } = {},
    selectedIndex,
    ...other
  } = props

  return (
    <div className={clsx(classes.root, className)} ref={ref} {...other}>
      <ButtonBase
        className={classes.button}
        onClick={onPrevClick}
        disabled={prevButtonDisabled}
        aria-label="Previous slide"
        style={{ flexGrow: selectedIndex * 2 + 1, ...PrevButtonStyle }}
        {...PrevButtonProps}
      >
        <ArrowPrevIcon className={classes.icon} />
      </ButtonBase>

      <span className={classes.label}>
        {selectedIndex + 1} / {listLength}
      </span>

      <ButtonBase
        className={classes.button}
        onClick={onNextClick}
        disabled={nextButtonDisabled}
        aria-label="Next slide"
        style={{ flexGrow: listLength * 2 - selectedIndex * 2, ...NextButtonStyle }}
        {...NextButtonProps}
      >
        <ArrowNextIcon className={classes.icon} />
      </ButtonBase>
    </div>
  )
})

SlideshowNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  listLength: PropTypes.number.isRequired,
  nextButtonDisabled: PropTypes.bool,
  NextButtonProps: PropTypes.object,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  prevButtonDisabled: PropTypes.bool,
  PrevButtonProps: PropTypes.object,
  selectedIndex: PropTypes.number.isRequired,
}

export default withStyles(styles)(SlideshowNavigation)
