import * as React from 'react'
import PropTypes from 'prop-types'
import { useEmblaCarousel } from 'embla-carousel/react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Image from 'next/image'
import clsx from 'clsx'
import Section from 'components/Section'
import SlideshowNavigation from 'components/SlideshowNavigation'
import Typography from 'components/Typography'
import Button from 'components/Button'
import RouterLink from 'containers/RouterLink'
import Tag from 'components/Tag'
import LinkArrow from 'components/LinkArrow'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('xs')]: {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  emblaRoot: {
    overflow: 'hidden',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      position: 'absolute', // Not `display: none` to get correct `scrollSnapList`
      opacity: 0,
      pointerEvents: 'none',
    },
  },
  emblaWrapper: {
    display: 'flex',
    margin: '0 calc(var(--coa-container-spacing) / 2 * -1)',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      margin: 0,
    },
  },
  emblaSlide: {
    position: 'relative',
    flexShrink: 0,
    width: '100%',
    padding: '0 calc(var(--coa-container-spacing) / 2 * 1)',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      padding: 0,
    },
  },
  navigation: {
    padding: 'var(--coa-section-spacing) var(--coa-container-spacing)',
    paddingTop: 0,
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      display: 'none',
    },
  },
  list: {
    ...theme.mixins.horizontalBorders(theme.palette.divider),
    display: 'none',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      display: 'grid',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    },
  },
  listItemOdd: {
    [theme.breakpoints.down('sm')]: {
      borderRight: `1px solid ${theme.palette.divider}`,
      borderLeft: 'none',
      '&:nth-child(even)': {
        borderRight: 'none',
      },
      '&:last-child': {
        borderRight: `1px solid ${theme.palette.divider}`,
        borderTop: 'none',
      },
      '&:nth-last-child(2)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderLeft: 'none',
      },
      '&:nth-last-child(3)': {
        borderRight: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  listItem: {
    flexGrow: 1,
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: {
      '&:nth-child(3n)': {
        borderLeft: 'none',
      },
    },
  },
  article: {
    maxWidth: 650,
    padding: 'var(--coa-section-spacing) var(--coa-container-spacing)',
  },
  articleImage: {
    width: 'auto',
    height: 44,
    marginBottom: theme.spacing(1),
  },
  articleHeading: {},
  articleText: {},
  cta: {
    marginTop: '24px',
  },
  backgroundMediaSrcContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: -5,
  },
  backgroundMediaSrcContainerWithCTA: {
    zIndex: 5,
  },
  articleContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  articleWithCTA: {
    position: 'relative',
    width: 'fit-content',
    height: 'fit-content',
    zIndex: 10,
  },
}))

function UspGroup(props) {
  const { items } = props
  const classes = useStyles(props)

  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  })

  const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [scrollSnaps, setScrollSnaps] = React.useState([])

  const handlePrev = React.useCallback(() => embla && embla.scrollPrev(), [embla])
  const handleNext = React.useCallback(() => embla && embla.scrollNext(), [embla])

  const handleSelect = React.useCallback(() => {
    setSelectedIndex(embla?.selectedScrollSnap())
    setPrevBtnEnabled(embla?.canScrollPrev())
    setNextBtnEnabled(embla?.canScrollNext())
  }, [embla, setSelectedIndex])

  React.useEffect(() => {
    if (!embla) return
    handleSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', handleSelect)
  }, [embla, handleSelect])

  const articles = items?.map((item, idx) => (
    <article key={idx} className={classes.articleContainer}>
      {item.backgroundMediaSrc && (
        <div
          className={clsx(classes.backgroundMediaSrcContainer, {
            [classes.backgroundMediaSrcContainerWithCTA]: item.backgroundMediaCTA,
          })}
        >
          {item.backgroundMediaCTA ? (
            <Tag
              component="a"
              styleRoot={false}
              href={item.backgroundMediaCTA || '#'}
              aria-label={`${item.heading} media CTA`}
              style={{ height: '100%', width: '100%' }}
            >
              <Image
                src={item.backgroundMediaSrc}
                alt={item.heading}
                layout="fill"
                objectFit="cover"
                size="(max-width: 600px) 100vw, 33vw"
              />
            </Tag>
          ) : (
            <Image
              src={item.backgroundMediaSrc}
              alt={item.heading}
              layout="fill"
              objectFit="cover"
              size="(max-width: 600px) 100vw, 33vw"
            />
          )}
        </div>
      )}
      <div
        className={clsx(classes.article, {
          [classes.articleWithCTA]: item.backgroundMediaSrc && item.backgroundMediaCTA,
        })}
      >
        {item.mediaSrc && (
          <img
            className={classes.articleImage}
            src={item.mediaSrc}
            width={156}
            height={59}
            alt={`${item.text || item.heading || `Usp Item ${idx}`}`}
          />
        )}

        <Typography
          className={classes.articleHeading}
          variant="h6"
          paragraph
          style={{
            color: item.headingColor === 'secondary' ? '#FFFFFF' : '#2E2630',
            fontSize: item.headingSize || 'large',
          }}
        >
          {item.heading}
        </Typography>

        <Typography
          className={classes.articleText}
          style={{
            color: item.textColor === 'secondary' ? '#FFFFFF' : '#2E2630',
            fontSize: item.fontSize || 'medium',
          }}
        >
          {item.text}
        </Typography>

        {item.ctaLabel && item.ctaUrl && (
          <>
            {item.ctaType === 'link' ? (
              <LinkArrow className={classes.cta} component={RouterLink} href={item.ctaUrl}>
                {item.ctaLabel}
              </LinkArrow>
            ) : (
              <Button
                className={classes.cta}
                component={RouterLink}
                href={item.ctaUrl}
                variant={item.ctaType ?? 'contained'}
                size={item.ctaButtonSize ?? 'medium'}
                fullWidth={item.ctaButtonFullRow ?? false}
              >
                {item.ctaLabel}
              </Button>
            )}
          </>
        )}
      </div>
    </article>
  ))

  return (
    <Section className={classes.root}>
      <div className={classes.emblaRoot} ref={emblaRef}>
        <div className={classes.emblaWrapper}>
          {articles?.map((article, idx) => (
            <div key={idx} className={classes.emblaSlide}>
              {article}
            </div>
          ))}
        </div>
      </div>

      <div className={classes.list}>
        {articles?.map((article, idx) => (
          <div
            key={idx}
            className={clsx(classes.listItem, {
              [classes.listItemOdd]: items.length % 2 === 1,
            })}
          >
            {article}
          </div>
        ))}
      </div>

      <SlideshowNavigation
        className={classes.navigation}
        listLength={scrollSnaps.length}
        nextButtonDisabled={!nextBtnEnabled}
        onNextClick={handleNext}
        onPrevClick={handlePrev}
        prevButtonDisabled={!prevBtnEnabled}
        selectedIndex={selectedIndex}
      />
    </Section>
  )
}

const itemType = PropTypes.shape({
  mediaSrc: PropTypes.string,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
})

UspGroup.propTypes = {
  items: PropTypes.arrayOf(itemType).isRequired,
}

export default UspGroup
